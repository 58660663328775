<!-- Notifications toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="cart.length > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{cart.length}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
</button>


<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Carrito de Compra</div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let product of cart; trackBy: trackByFn">
                <div
                    class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': product}">


                    <!-- Notification without a link -->
                    <ng-container>
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>

                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <button
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                            mat-icon-button
                            (click)="delete(product)"
                            [matTooltip]="'Remove'">
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>

                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Image -->
                    <ng-container>
                        <img
                            class="shrink-0 w-12 h-12 mr-4 overflow-hidden object-cover object-center"
                            src="https://img.zsmotor.cl/fotos/app/{{product.codigo}}-1.jpg" onerror="this.src='https://app.zsmotor.cl/assets/images/logo/logo_zs_motor_1.png'"
                            [alt]="'Notification image'">
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container>
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="product.codigo"></div>
                        </ng-container>
                        <ng-container>
                            <div
                                class="line-clamp-2"
                                >{{product.descripcion}}</div>
                            <div
                                class="line-clamp-2"
                                >Cantidad: {{product.cantidad}}</div>
                            <div
                            class="line-clamp-2"
                            >Precio: ${{product.preciomayor*product.cantidad | number}}</div>

                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!cart || !cart.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon
                            class="text-primary-500-700"
                            [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No existen items!</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">No hay items en el carro de compras</div>
                </div>
            </ng-container>

            <div *ngIf="cart.length > 0 " class="grid grid-cols-1 px-4 py-4 w-full min-w-0">
                <a
                    class="fuse-mat-button-rounded w-full mx-2 my-1 sm:mt-6 bg-emerald-600"
                    mat-raised-button
                    [routerLink]="['/checkout']"
                    (click)="esconder_carro()"
                    [color]="'accent'">
                    <mat-icon
                        class="icon-size-5 text-white"
                        [svgIcon]="'heroicons_solid:shopping-cart'"></mat-icon>
                    <span class="ml-1 text-white "> Ingresar Orden</span>
                </a>
                <button
                    class="fuse-mat-button-rounded w-full mx-2 my-1 sm:mt-6 bg-warn-700"
                    mat-flat-button
                    [color]="'warn'"
                    (click)="deleteCart()">
                    <mat-icon
                    class="icon-size-5 text-white"
                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                    <span class="ml-2">Vaciar Carro</span>
                </button>

            </div>

        </div>

    </div>

</ng-template>
