<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div style="margin-top:10%" class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mb-4">
            <img
                class="w-100"
                src="assets/images/logo/logo_zs_motor.png">
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="md:pr-80 md:px-0 md:pl-4 fixed flex flex-row flex-auto justify-between items-center w-full h-16 px-4  z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        
        
        <div class="flex sm:hidden  items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <shortcuts></shortcuts>
            <notifications></notifications> -->
            <img
            class="w-30"
            src="assets/images/logo/logo_zs_motor_negro.png">
        </div>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <shortcuts></shortcuts>
            <notifications></notifications> -->
            <cart></cart>

        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto pt-16 pb-24">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
    <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-10 md:hidden bg-white shadow py-5">
		<div id="tabs" class="flex justify-between">
			<a [routerLink]="['/']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
				<svg width="25" height="25" viewBox="0 0 42 42" class="inline-block mb-1">
			    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			        <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fill-opacity="0.1"></path>
			        <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
			        <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" stroke-width="2" fill="currentColor" fill-opacity="0.1"></path>
			        <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
			        <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
			    </g>
				</svg>
				<span class="tab tab-home block text-xs">Home</span>
			</a>
			<a [routerLink]="['/consultas']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                <mat-icon
                class="text-primary-500-700"
                [svgIcon]="'feather:package'"></mat-icon>
				<span class="tab tab-kategori block text-xs">Productos</span>
			</a>
			<a [routerLink]="['/clientes']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
				<mat-icon
                class="text-primary-500-700"
                [svgIcon]="'heroicons_outline:users'"></mat-icon>
				<span class="tab tab-explore block text-xs">Clientes</span>
			</a>
			<!-- <a [routerLink]="['/ingreso_servicio']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
				<mat-icon
                class="text-primary-500-700"
                [svgIcon]="'mat_solid:directions_car_filled'"></mat-icon>
				<span class="tab tab-explore block text-xs">Patente</span>
			</a> -->
		</div>
	</section>
</div>