import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboards/project'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboards/project'},
    {path: 'eventos/registro', loadChildren: () => import('app/modules/eventos/landing_page/landing_page.module').then(m => m.LandingPageModule)},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},

        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
            {path: 'asistencia', loadChildren: () => import('app/modules/admin/asistencia/asistencia.module').then(m => m.AsistenciaModule)},
            {path: 'ingreso_servicio', loadChildren: () => import('app/modules/admin/ingreso_servicio/ingreso_servicio.module').then(m => m.IngresoServicioModule)},
            {path: 'consultas', loadChildren: () => import('app/modules/admin/consultas/consultas.module').then(m => m.ConsultasModule)},
            {path: 'clientes', loadChildren: () => import('app/modules/admin/clientes/clientes.module').then(m => m.ClientesModule)},
            {path: 'checkout', loadChildren: () => import('app/modules/admin/checkout/checkout.module').then(m => m.CheckoutModule)},
            {path: 'config_eventos', loadChildren: () => import('app/modules/admin/eventos/eventos.module').then(m => m.EventosModule)},
            {path: 'mis_servicios', loadChildren: () => import('app/modules/admin/mis_servicios/mis_servicios.module').then(m => m.MisServiciosModule)},
            {path: 'status_taller', loadChildren: () => import('app/modules/admin/status_taller/status_taller.module').then(m => m.StatusTallerModule)},
            {path: 'adm_usuarios', loadChildren: () => import('app/modules/admin/adm_usuarios/adm_usuarios.module').then(m => m.AdmUsuariosModule)},
            {path: 'configuracion', loadChildren: () => import('app/modules/admin/configuracion/configuracion.module').then(m => m.ConfiguracionModule)},
            {path: 'dashboards', children: [
                {path: 'project', loadChildren: () => import('app/modules/admin/project/project.module').then(m => m.ProjectModule)},
            ]},

        ]

    }
];
