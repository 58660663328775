import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {
  @Output() disparador_cart: EventEmitter<any> = new EventEmitter();
  @Output() disparador_cart_2: EventEmitter<any> = new EventEmitter();
  @Output() change_stock: EventEmitter<any> = new EventEmitter();
  @Output() change_price: EventEmitter<any> = new EventEmitter();
  @Output() save_tire_report: EventEmitter<any> = new EventEmitter();
  @Output() select_customer: EventEmitter<any> = new EventEmitter();
  @Output() customer_edit: EventEmitter<any> = new EventEmitter();
  @Output() customer_create: EventEmitter<any> = new EventEmitter();
  constructor() { }
}
