/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // },
    {
        id   : 'project',
        title: 'Home',
        type : 'basic',
        icon : 'heroicons_solid:home',
        link : '/dashboards/project'
    },
    {
        id   : 'consultas',
        title: 'Productos',
        type : 'basic',
        icon : 'feather:package',
        link : '/consultas'
    },
    // {
    //     id   : 'mis_servicios',
    //     title: 'Servicios',
    //     type : 'basic',
    //     icon : 'feather:package',
    //     link : '/mis_servicios'
    // },
    {
        id   : 'clientes',
        title: 'Clientes',
        type : 'basic',
        icon : 'heroicons_solid:users',
        link : '/clientes'
    },
    {
        id   : 'status_taller',
        title: 'Estado Del Taller',
        type : 'basic',
        icon : 'car_repair',
        link : '/status_taller'
    },
    {
        id   : 'adm_usuarios',
        title: 'Administración Usuarios',
        type : 'basic',
        icon : 'heroicons_solid:user-add',
        link : '/adm_usuarios'
    },
    {
        id   : 'eventos',
        title: 'Eventos',
        type : 'basic',
        icon : 'store',
        link : '/config_eventos'
    },
    {
        id   : 'configuracion',
        title: 'Configuracion',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        link : '/configuracion'
    },
    {
        id   : 'sign-out',
        title: 'Cerrar Sesión',
        type : 'basic',
        icon : 'heroicons_solid:logout',
        link : '/sign-out'
    }

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
