import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { BASE_URL_BACKEND } from 'app/core/config/app.config';
import axios from 'axios'

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set refreshToken(token: string)
    {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string
    {
        return localStorage.getItem('refreshToken') ?? '';
    }

    set user_email(email: string)
    {
        localStorage.setItem('user_email', email);
    }

    get user_email(): string
    {
        return localStorage.getItem('user_email') ?? '';
    }

    set user_name(name: string)
    {
        localStorage.setItem('user_name', name);
    }

    get user_name(): string
    {
        return localStorage.getItem('user_name') ?? '';
    }

    set permissions(permissions_obj: string)
    {
        localStorage.setItem('permissions', permissions_obj);
    }

    get permissions(): string
    {
        return localStorage.getItem('permissions') ?? '';
    }

    set discount(discount: string)
    {
        localStorage.setItem('discount', discount);
    }

    get discount(): string
    {
        return localStorage.getItem('discount') ?? '';
    }

    set random_code(random_code: string)
    {
        localStorage.setItem('random_code', random_code);
    }

    get random_code(): string
    {
        return localStorage.getItem('random_code') ?? '';
    }

    set jefe_local_name(jefe_local_name: string)
    {
        localStorage.setItem('jefe_local_name', jefe_local_name);
    }

    get jefe_local_name(): string
    {
        return localStorage.getItem('jefe_local_name') ?? '';
    }

    set jefe_local(jefe_local: string)
    {
        localStorage.setItem('jefe_local', jefe_local);
    }

    get jefe_local(): string
    {
        return localStorage.getItem('jefe_local') ?? '';
    }

    set bodega_default(bodega_default: string)
    {
        localStorage.setItem('bodega_default', bodega_default);
    }

    get bodega_default(): string
    {
        return localStorage.getItem('bodega_default') ?? '';
    }

    set sucursal_default(sucursal_default: string)
    {
        localStorage.setItem('sucursal_default', sucursal_default);
    }

    get sucursal_default(): string
    {
        return localStorage.getItem('sucursal_default') ?? '';
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(BASE_URL_BACKEND+'/api/token/', credentials).pipe(
            switchMap((response: any) => {
                console.log(response)
                // Store the access token in the local storage
                this.accessToken = response.access;
                this.refreshToken = response.refresh;
                localStorage.setItem("cart", "[]")
                this._authenticated = true;

                return this._httpClient.get(BASE_URL_BACKEND+"/api/core/user/").pipe(
                    catchError(() =>

                        // Return false
                        of(false)
                    ),
                    switchMap((response: any) => {

                        // Store the access token in the local storage
                        this.permissions = btoa(JSON.stringify(response.permisos))
                        this.user_name = response.nombre
                        this.discount = btoa(response.discount)
                        this.user_email = response.email
                        this.random_code = response.random_code
                        this.jefe_local = response.jefe_local
                        this.jefe_local_name = response.jefe_local_name
                        this.bodega_default = response.bodega_default
                        this.sucursal_default = response.sucursal_default
                        this._authenticated = true;
                        // Return true
                        return of(true);
                    })
                );

                // Store the user on the user service
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post(BASE_URL_BACKEND+'/api/token/refresh/', {
            refresh: this.refreshToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access;

                // Set the authenticated flag to true
                this._authenticated = true;

                return this._httpClient.get(BASE_URL_BACKEND+"/api/core/user/").pipe(
                    catchError(() =>

                        // Return false
                        of(false)
                    ),
                    switchMap((response: any) => {

                        // Store the access token in the local storage
                        this.user_name = response.nombre
                        this.user_email = response.email
                        this.discount = btoa(response.discount)
                        this.permissions = btoa(JSON.stringify(response.permisos))
                        this._authenticated = true;
                        this.random_code = response.random_code
                        this.jefe_local = response.jefe_local
                        this.jefe_local_name = response.jefe_local_name
                        this.bodega_default = response.bodega_default
                        this.sucursal_default = response.sucursal_default

                        // Return true
                        return of(true);
                    })
                );

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            console.log("entre")
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
