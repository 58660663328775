/* eslint-disable */
export const shortcuts = [
    {
        id         : 'a1ae91d3-e2cb-459b-9be9-a184694f548b',
        label      : 'Nuevo Servicio',
        description: 'Iniciar Nuevo Servicio',
        icon       : 'heroicons_solid:plus',
        link       : '/ingreso_servicio',
        useRouter  : true
    }
];
